"use strict";

function loadTemplate() {
	var isNoviBuilder = false;
	var plugins = {
		bootstrapTooltip:        $( '[data-toggle="tooltip"]' ),
		bootstrapModal:          $( '.modal' ),
		bootstrapTabs:           $( '.tabs-custom' ),
		customToggle:            $( '[data-custom-toggle]' ),
		captcha:                 $( '.recaptcha' ),
		campaignMonitor:         $( '.campaign-mailform' ),
		copyrightYear:           $( '.copyright-year' ),
		checkbox:                $( 'input[type="checkbox"]' ),
		isotope:                 $( '.isotope-wrap' ),
		lightGallery:            $( '[data-lightgallery="group"]' ),
		lightGalleryItem:        $( '[data-lightgallery="item"]' ),
		lightDynamicGalleryItem: $( '[data-lightgallery="dynamic"]' ),
		materialParallax:        $( '.parallax-container' ),
		mailchimp:               $( '.mailchimp-mailform' ),
		owl:                     $( '.owl-carousel' ),
		popover:                 $( '[data-toggle="popover"]' ),
		preloader:               $( '.preloader' ),
		rdNavbar:                $( '.rd-navbar' ),
		rdMailForm:              $( '.rd-mailform' ),
		rdInputLabel:            $( '.form-label' ),
		regula:                  $( '[data-constraints]' ),
		radio:                   $( 'input[type="radio"]' ),
		swiper:                  $( '.swiper-container' ),
		search:                  $( '.rd-search' ),
		searchResults:           $( '.rd-search-results' ),
		statefulButton:          $( '.btn-stateful' ),
		viewAnimate:             $( '.view-animate' ),
		wow:                     $( '.wow' ),
		maps:                    $( '.google-map-container' ),
		counter:                 document.querySelectorAll( '.counter' ),
		progressLinear:          document.querySelectorAll( '.progress-linear' ),
		progressCircle:          document.querySelectorAll( '.progress-circle' ),
		countdown:               document.querySelectorAll( '.countdown' )
	};
	
	if (plugins.preloader.length && !isNoviBuilder) {
		pageTransition({
			target: document.querySelector( '.page' ),
			delay: 0,
			duration: 500,
			classIn: 'fadeIn',
			classOut: 'fadeOut',
			classActive: 'animated',
			conditions: function (event, link) {
				return link && !/(\#|javascript:void\(0\)|callto:|tel:|mailto:|:\/\/)/.test(link) && !event.currentTarget.hasAttribute('data-lightgallery');
			},
			onTransitionStart: function ( options ) {
				setTimeout( function () {
					plugins.preloader.removeClass('loaded');
				}, options.duration * .75 );
			},
			onReady: function () {
				plugins.preloader.addClass('loaded');
				windowReady = true;
			}
		});
	}

	// Counter
	if ( plugins.counter ) {
		for ( var i = 0; i < plugins.counter.length; i++ ) {
			var
				node = plugins.counter[i],
				counter = aCounter({
					node: node,
					duration: node.getAttribute( 'data-duration' ) || 1000
				}),
				scrollHandler = (function() {
					if ( Util.inViewport( this ) && !this.classList.contains( 'animated-first' ) ) {
						this.counter.run();
						this.classList.add( 'animated-first' );
					}
				}).bind( node ),
				blurHandler = (function() {
					this.counter.params.to = parseInt( this.textContent, 10 );
					this.counter.run();
				}).bind( node );

			if ( isNoviBuilder ) {
				node.counter.run();
				node.addEventListener( 'blur', blurHandler );
			} else {
				scrollHandler();
				window.addEventListener( 'scroll', scrollHandler );
			}
		}
	}

	// Progress Bar
	if ( plugins.progressLinear ) {
		for ( var i = 0; i < plugins.progressLinear.length; i++ ) {
			var
				container = plugins.progressLinear[i],
				counter = aCounter({
					node: container.querySelector( '.progress-linear-counter' ),
					duration: container.getAttribute( 'data-duration' ) || 1000,
					onStart: function() {
						this.custom.bar.style.width = this.params.to + '%';
					}
				});

			counter.custom = {
				container: container,
				bar: container.querySelector( '.progress-linear-bar' ),
				onScroll: (function() {
					if ( ( Util.inViewport( this.custom.container ) && !this.custom.container.classList.contains( 'animated' ) ) || isNoviBuilder ) {
						this.run();
						this.custom.container.classList.add( 'animated' );
					}
				}).bind( counter ),
				onBlur: (function() {
					this.params.to = parseInt( this.params.node.textContent, 10 );
					this.run();
				}).bind( counter )
			};

			if ( isNoviBuilder ) {
				counter.run();
				counter.params.node.addEventListener( 'blur', counter.custom.onBlur );
			} else {
				counter.custom.onScroll();
				document.addEventListener( 'scroll', counter.custom.onScroll );
			}
		}
	}

	// Progress Circle
	if ( plugins.progressCircle ) {
		for ( var i = 0; i < plugins.progressCircle.length; i++ ) {
			var
				container = plugins.progressCircle[i],
				counter = aCounter({
					node: container.querySelector( '.progress-circle-counter' ),
					duration: 500,
					onUpdate: function( value ) {
						this.custom.bar.render( value * 3.6 );
					}
				});

			counter.params.onComplete = counter.params.onUpdate;

			counter.custom = {
				container: container,
				bar: aProgressCircle({ node: container.querySelector( '.progress-circle-bar' ) }),
				onScroll: (function() {
					if ( Util.inViewport( this.custom.container ) && !this.custom.container.classList.contains( 'animated' ) ) {
						this.run();
						this.custom.container.classList.add( 'animated' );
					}
				}).bind( counter ),
				onBlur: (function() {
					this.params.to = parseInt( this.params.node.textContent, 10 );
					this.run();
				}).bind( counter )
			};

			if ( isNoviBuilder ) {
				counter.run();
				counter.params.node.addEventListener( 'blur', counter.custom.onBlur );
			} else {
				counter.custom.onScroll();
				window.addEventListener( 'scroll', counter.custom.onScroll );
			}
		}
	}

	// Isotope
	if ( plugins.isotope.length ) {
		for ( var i = 0; i < plugins.isotope.length; i++ ) {
			var
				wrap = plugins.isotope[ i ],
				filterHandler = function ( event ) {
					event.preventDefault();
					for ( var n = 0; n < this.isoGroup.filters.length; n++ ) this.isoGroup.filters[ n ].classList.remove( 'active' );
					this.classList.add( 'active' );
					this.isoGroup.isotope.arrange( { filter: this.getAttribute( "data-isotope-filter" ) !== '*' ? '[data-filter*="' + this.getAttribute( "data-isotope-filter" ) + '"]' : '*' } );
				},
				resizeHandler = function () {
					this.isoGroup.isotope.layout();
				};

			wrap.isoGroup = {};
			wrap.isoGroup.filters = wrap.querySelectorAll( '[data-isotope-filter]' );
			wrap.isoGroup.node = wrap.querySelector( '.isotope' );
			wrap.isoGroup.layout = wrap.isoGroup.node.getAttribute( 'data-isotope-layout' ) ? wrap.isoGroup.node.getAttribute( 'data-isotope-layout' ) : 'masonry';
			wrap.isoGroup.isotope = new Isotope( wrap.isoGroup.node, {
				itemSelector: '.isotope-item',
				layoutMode: wrap.isoGroup.layout,
				filter: '*',
				columnWidth: ( function() {
					if ( wrap.isoGroup.node.hasAttribute('data-column-class') ) return wrap.isoGroup.node.getAttribute('data-column-class');
					if ( wrap.isoGroup.node.hasAttribute('data-column-width') ) return parseFloat( wrap.isoGroup.node.getAttribute('data-column-width') );
				}() )
			} );

			for ( var n = 0; n < wrap.isoGroup.filters.length; n++ ) {
				var filter = wrap.isoGroup.filters[ n ];
				filter.isoGroup = wrap.isoGroup;
				filter.addEventListener( 'click', filterHandler );
			}

			window.addEventListener( 'resize', resizeHandler.bind( wrap ) );
		}
	}

	// Material Parallax
	if ( plugins.materialParallax.length ) {
		if ( !isNoviBuilder && !isIE && !isMobile) {
			plugins.materialParallax.parallax();
		} else {
			for ( var i = 0; i < plugins.materialParallax.length; i++ ) {
				var $parallax = $(plugins.materialParallax[i]);

				$parallax.addClass( 'parallax-disabled' );
				$parallax.css({ "background-image": 'url('+ $parallax.data("parallax-img") +')' });
			}
		}
	}
	//swiper
	
	
};
function onloadComplete()
{
	var $document = $(document),
		$window = $(window),
		$html = $("html"),
		$body = $("body"),

		isDesktop = $html.hasClass("desktop"),
		windowReady = false,
		isNoviBuilder = false,
		livedemo = false,
	   swiper = $( '.swiper-container' ); 
	if (swiper.length) {
		
		for (let i = 0; i < swiper.length; i++) {

			let
				node = swiper[i],
				params = parseJSON(node.getAttribute('data-swiper')),
				defaults = {
					speed:      1000,
					loop:       false,
					pagination: {
						el:        '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
					autoplay:   {
						delay: 5000
					},
					slidesPerView: 'auto',
				},
				xMode = {
					autoplay:      true,
					loop:          false,
					simulateTouch: false
				};

			params.on = {
				init: function () {
					toggleSwiperInnerVideos(this);
					setBackgrounds(this);
					setRealPrevious(this);
					initCaptionAnimate(this);
					initLightGalleryItem($(node).find('[data-lightgallery="item"]'), 'lightGallery-in-carousel',isNoviBuilder);
					// Real Previous Index must be set recent
					this.on('slideChangeTransitionEnd', function () {
					 	setRealPrevious(this);
					});
				}
			};
			//new Swiper( node, Util.merge( isNoviBuilder ? [ defaults, params, xMode ] : [ defaults, params ] ) );
			new Swiper( node, Util.merge( [ defaults, params, xMode ] ) );
		}
	}
	InitRDNavBar(isNoviBuilder);
	if ($html.hasClass("wow-animation") && $('.wow').length && !isNoviBuilder && isDesktop) {
		//new WOW().init();
	}
}
// RD Navbar
function InitRDNavBar(isNoviBuilder) {
	//var isNoviBuilder = window.xMode;
	var rdNav = $('.rd-navbar');
	if ( rdNav.length ) {
		var
			navbar = rdNav,
			aliases = { '-': 0, '-sm-': 576, '-md-': 768, '-lg-': 992, '-xl-': 1200, '-xxl-': 1600 },
			responsive = {};

		for ( var alias in aliases ) {
			var link = responsive[ aliases[ alias ] ] = {};
			if ( navbar.attr( 'data'+ alias +'layout' ) )          link.layout        = navbar.attr( 'data'+ alias +'layout' );
			if ( navbar.attr( 'data'+ alias +'device-layout' ) )   link.deviceLayout  = navbar.attr( 'data'+ alias +'device-layout' );
			if ( navbar.attr( 'data'+ alias +'hover-on' ) )        link.focusOnHover  = navbar.attr( 'data'+ alias +'hover-on' ) === 'true';
			if ( navbar.attr( 'data'+ alias +'auto-height' ) )     link.autoHeight    = navbar.attr( 'data'+ alias +'auto-height' ) === 'true';
			if ( navbar.attr( 'data'+ alias +'stick-up-offset' ) ) link.stickUpOffset = navbar.attr( 'data'+ alias +'stick-up-offset' );
			if ( navbar.attr( 'data'+ alias +'stick-up' ) )        link.stickUp       = navbar.attr( 'data'+ alias +'stick-up' ) === 'true';
			if ( isNoviBuilder ) link.stickUp = false;
			else if ( navbar.attr( 'data'+ alias +'stick-up' ) )   link.stickUp       = navbar.attr( 'data'+ alias +'stick-up' ) === 'true';
		}

		rdNav.RDNavbar({
			anchorNav: !isNoviBuilder,
			stickUpClone: (rdNav.attr("data-stick-up-clone") && !isNoviBuilder) ? rdNav.attr("data-stick-up-clone") === 'true' : false,
			responsive: responsive,
			callbacks: {
				onStuck: function () {
					var navbarSearch = this.$element.find('.rd-search input');

					if (navbarSearch) {
						navbarSearch.val('').trigger('propertychange');
					}
				},
				onDropdownOver: function () {
					return !isNoviBuilder;
				},
				onUnstuck: function () {
					if (this.$clone === null)
						return;

					var navbarSearch = this.$clone.find('.rd-search input');

					if (navbarSearch) {
						navbarSearch.val('').trigger('propertychange');
						navbarSearch.trigger('blur');
					}

				}
			}
		});
	}
}
function setRealPrevious(swiper) {
	let element = swiper.$wrapperEl[0].children[swiper.activeIndex];
	swiper.realPrevious = Array.prototype.indexOf.call(element.parentNode.children, element);
}

/**
 * @desc Sets slides background images from attribute 'data-slide-bg'
 * @param {object} swiper - swiper instance
 */
function setBackgrounds(swiper) {
	let swipersBg = swiper.el.querySelectorAll('[data-slide-bg]');

	for (let i = 0; i < swipersBg.length; i++) {
		let swiperBg = swipersBg[i];
		swiperBg.style.backgroundImage = 'url(' + swiperBg.getAttribute('data-slide-bg') + ')';
		swiperBg.style.backgroundSize = 'cover';
	}
}

/**
 * @desc Animate captions on active slides
 * @param {object} swiper - swiper instance
 */
function initCaptionAnimate(swiper) {
	let
		animate = function (caption) {
			return function () {
				let duration;
				if (duration = caption.getAttribute('data-caption-duration')) caption.style.animationDuration = duration + 'ms';
				caption.classList.remove('not-animated');
				caption.classList.add(caption.getAttribute('data-caption-animate'));
				caption.classList.add('animated');
			};
		},
		initializeAnimation = function (captions) {
			for (let i = 0; i < captions.length; i++) {
				let caption = captions[i];
				caption.classList.remove('animated');
				caption.classList.remove(caption.getAttribute('data-caption-animate'));
				caption.classList.add('not-animated');
			}
		},
		finalizeAnimation = function (captions) {
			for (let i = 0; i < captions.length; i++) {
				let caption = captions[i];
				if (caption.getAttribute('data-caption-delay')) {
					setTimeout(animate(caption), Number(caption.getAttribute('data-caption-delay')));
				} else {
					animate(caption)();
				}
			}
		};

	// Caption parameters
	swiper.params.caption = {
		animationEvent: 'slideChangeTransitionEnd'
	};

	initializeAnimation(swiper.$wrapperEl[0].querySelectorAll('[data-caption-animate]'));
	finalizeAnimation(swiper.$wrapperEl[0].children[swiper.activeIndex].querySelectorAll('[data-caption-animate]'));

	if (swiper.params.caption.animationEvent === 'slideChangeTransitionEnd') {
		swiper.on(swiper.params.caption.animationEvent, function () {
			initializeAnimation(swiper.$wrapperEl[0].children[swiper.previousIndex].querySelectorAll('[data-caption-animate]'));
			finalizeAnimation(swiper.$wrapperEl[0].children[swiper.activeIndex].querySelectorAll('[data-caption-animate]'));
		});
	} else {
		swiper.on('slideChangeTransitionEnd', function () {
			initializeAnimation(swiper.$wrapperEl[0].children[swiper.previousIndex].querySelectorAll('[data-caption-animate]'));
		});

		swiper.on(swiper.params.caption.animationEvent, function () {
			finalizeAnimation(swiper.$wrapperEl[0].children[swiper.activeIndex].querySelectorAll('[data-caption-animate]'));
		});
	}
}
/**
 * @desc Toggle swiper videos on active slides
 * @param {object} swiper - swiper slider
 */
function toggleSwiperInnerVideos(swiper) {
	var prevSlide = $(swiper.slides[swiper.previousIndex]),
		nextSlide = $(swiper.slides[swiper.activeIndex]),
		videos,
		videoItems = prevSlide.find("video");

	for (var i = 0; i < videoItems.length; i++) {
		videoItems[i].pause();
	}

	videos = nextSlide.find("video");
	if (videos.length) {
		videos.get(0).play();
	}
}

/**
 * @desc Toggle swiper animations on active slides
 * @param {object} swiper - swiper slider
 */
function toggleSwiperCaptionAnimation(swiper) {
	var prevSlide = $(swiper.container).find("[data-caption-animate]"),
		nextSlide = $(swiper.slides[swiper.activeIndex]).find("[data-caption-animate]"),
		delay,
		duration,
		nextSlideItem,
		prevSlideItem;

	for (var i = 0; i < prevSlide.length; i++) {
		prevSlideItem = $(prevSlide[i]);

		prevSlideItem.removeClass("animated")
			.removeClass(prevSlideItem.attr("data-caption-animate"))
			.addClass("not-animated");
	}


	var tempFunction = function (nextSlideItem, duration) {
		return function () {
			nextSlideItem
				.removeClass("not-animated")
				.addClass(nextSlideItem.attr("data-caption-animate"))
				.addClass("animated");
			if (duration) {
				nextSlideItem.css('animation-duration', duration + 'ms');
			}
		};
	};

	for (var i = 0; i < nextSlide.length; i++) {
		nextSlideItem = $(nextSlide[i]);
		delay = nextSlideItem.attr("data-caption-delay");
		duration = nextSlideItem.attr('data-caption-duration');
		if (!isNoviBuilder) {
			if (delay) {
				setTimeout(tempFunction(nextSlideItem, duration), parseInt(delay, 10));
			} else {
				tempFunction(nextSlideItem, duration);
			}

		} else {
			nextSlideItem.removeClass("not-animated")
		}
	}
}

function parseJSON ( str ) {
	try {
		if ( str )  return JSON.parse( str );
		else return {};
	} catch ( error ) {
		console.warn( error );
		return {};
	}
}

/**
		 * @desc Initialize the gallery with one image
		 * @param {object} itemToInit - jQuery object
		 * @param {string} [addClass] - additional gallery class
		 */
 function initLightGalleryItem ( itemToInit, addClass,isNoviBuilder ) {
	if ( !isNoviBuilder ) {
		$( itemToInit ).lightGallery( {
			selector: "this",
			addClass: addClass,
			counter: false,
			youtubePlayerParams: {
				modestbranding: 1,
				showinfo: 0,
				rel: 0,
				controls: 0
			},
			vimeoPlayerParams: {
				byline: 0,
				portrait: 0
			}
		} );
	}
}
